import React from "react";
import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import TenantPage from "./pages/TenantPage";

const theme = createTheme({
  palette: {
    primary: {
      main: "#257cff",
    },
    secondary: {
      main: "#f45a2a",
    },
    background: {
      default: "#e7f3fc",
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>

      <Routes>
        <Route path="/:id" element={<TenantPage />} />
      </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
