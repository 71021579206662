import React from "react";
import { Grid, Menu, MenuItem, Typography } from "@mui/material";

const languagesData = [
  {
    code: "en",
    name: "English",
    country_code: "gb",
  },
  {
    code: "nl",
    name: "Nederlands",
    country_code: "nl",
  },
];

export default function LanguageMenuSelection({
  anchorEl,
  setAnchorEl,
  setLanguageLabel,
}) {
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLanguageChange = (event) => {
    setLanguageLabel(
      languagesData.find(
        (language) => language.code === event.currentTarget.dataset.myValue
      ).name
    );
    setAnchorEl(null);
  };

  const languages = languagesData.map((language) => (
    <MenuItem
      onClick={handleLanguageChange}
      data-my-value={language.code}
      key={language.country_code}
      value={language.code}
    >
      <Grid
        mr={1}
        className={`flag-icon flag-icon-${language.country_code} mx-2`}
      ></Grid>
      <Typography sx={{ display: { md: "block", xs: "none" } }}>
        {language.name}
      </Typography>
      <Typography sx={{ display: { md: "none", xs: "block" } }}>
        {language.code.toUpperCase()}
      </Typography>
    </MenuItem>
  ));

  return (
    <div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        transformOrigin={{ vertical: "bottom", horizontal: "center" }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {languages}
      </Menu>
    </div>
  );
}
