import React from "react";

import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
} from "@mui/material";

const LoadingDataComponent = ({ alreadyRendered }) => {
  return (
    <Dialog open={!alreadyRendered}>
      <DialogContent>
        <DialogContentText>
          <CircularProgress />
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default LoadingDataComponent;
