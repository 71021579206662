import React from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  Grid,
} from "@mui/material";


const MessagePopup = ({ state, setState, message }) => {
  return (
    <Dialog
      open={state}
      onClose={() => setState(false)}
    >
      <DialogContent>
        <DialogContentText>
          {message} <br />
          <br />
          <Grid container justifyContent="center">
            <Button
              variant="contained"
              onClick={() => setState(false)}
            >
              OK
            </Button>
          </Grid>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};


export default MessagePopup;
