import React from 'react'
import { GeoapifyGeocoderAutocomplete, GeoapifyContext } from '@geoapify/react-geocoder-autocomplete'
import '@geoapify/geocoder-autocomplete/styles/round-borders.css'
import { Grid, Link } from "@mui/material";

const AutoCompleteAddress = ({ setStreet, setHouseNumber, setPostCode, setCity, addressLabel, placeHolderLabel }) => {

  function onPlaceSelect(value) {
    setStreet(value.properties.street)
    setPostCode(value.properties.postcode)
    setCity(value.properties.city)
    setHouseNumber(value.properties.housenumber)
  }


  return <div>
    <GeoapifyContext apiKey={process.env.REACT_APP_GEOAPIFY_MAPS_API}>
      <GeoapifyGeocoderAutocomplete placeholder={placeHolderLabel}
        placeSelect={onPlaceSelect}
        filterByCountryCode={['nl']}
        debounceDelay={500}
        skipDetails
      />
    </GeoapifyContext>
    <Grid style={{ fontStyle: "italic", fontSize: 12 }}>
      Powered by{"\u00a0"}
      <Link
        href="https://www.geoapify.com"
        color="inherit"
        target="_blank"
      >
        Geoapify
      </Link>
    </Grid>
  </div>
}

export default AutoCompleteAddress