import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

import LoadingDataComponent from "../components/LoadingDataComponent";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import ErrorIcon from "@mui/icons-material/Error";

import {
  Alert,
  AlertTitle,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  Link,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import LanguageIcon from "@mui/icons-material/Language";
import getBrightness from "getbrightness";
import cookies from "js-cookie";

import { Player } from "@lottiefiles/react-lottie-player";
import LanguageMenuSelection from "../components/LanguageMenuSelection";
import MessagePopup from "../components/MessagePopup";

import { ReactComponent as ProperizeWhiteLogo } from "../logos/properize-monochrome-white.svg";
import properizeLogo from "../logos/properize.png";

import configData from "../utils/config.json";
import navigatorLanguage from "../utils/navigatorLanguage";
import AutoCompleteAddress from "../components/AutoCompleteAddress";

const CssTextField = styled(TextField)({
  marginTop: 15,
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: "8px",
    },
  },
});

const paperStyle = {
  padding: 3,
  mx: 2,
  minWidth: 350,
  maxWidth: 450,
  bgcolor: "#f5f5f5",
  borderColor: "#257cff",
  borderRadius: 4,
  boxShadow: 3,
};

const languagesData = [
  {
    code: "en",
    name: "English",
    country_code: "gb",
  },
  {
    code: "nl",
    name: "Nederlands",
    country_code: "nl",
  },
];

const translationJSON = {
  English: {
    interested_in_renting_a_property: "Interested in renting a property?",
    let_us_know_here: "Let us know here",
    if_you_are_visiting_this_website1:
      "If you are visiting this website, it must be because you are interested in renting a property offered by ",
    if_you_are_visiting_this_website2:
      ". Fill in this form to formally apply for the property and initiate tenant screening",
    first_name: "First Name",
    last_name: "Last Name",
    email: "Email",
    continue: "Continue",
    addressLabel: "Rental property address",
    placeHolderLabel: "Start typing...",
    street: "Street",
    number: "Number",
    "Address Line 2": "Addition (optional)",
    post_code: "Post Code",
    city: "City",
    Country: "Country",
    rental_price: "Monthly Rental Price (EUR)",
    back: "Back",
    review_your_application: "Please review your tenant application",
    your_email_address: "Your e-mail address:",
    your_rental_property: "The rental property you are interested in:",
    monthly_rental_price: "Monthly Rental price:",
    renting_with_somebody_disclaimer:
      "If you are renting with somebody else, you will have the chance to add them later to your application",
    "By ticking, you agree to our": "By ticking, you agree to our ",
    "terms and conditions": "terms and conditions",
    and: " and ",
    "privacy policy": "privacy policy",
    tenant_application_sent: "Check your inbox",
    tenant_application_sent_paragraph:
      "In a few minutes you will receive an e-mail (please check your spam folder) from our partner Properize to conduct tenant screening.",
    you_can_click: "Alternatively, you can click below button to continue:",
    you_can_close_this_window: "You can now close this window",
    this_tenant_solution_is_powered_by:
      "This tenant onboarding solution is powered by",
    BACK: "BACK",
    SUBMIT: "SUBMIT",
    "You need to agree with terms and conditions":
      "You need to agree with our Terms and Conditions and Privacy Policy",
    "No valid address":
      "The address is invalid/incomplete. Please go back, enter the address and select one of the suggested locations",
    "Duplicate popup title": "You have an active request",
    "Active request landing page1": "You (or your co-tenant) have recently created a request with ",
    "Please read": "Please read",
    "Active request landing page2 dupes": ". Are you sure you want to create a new one?",
    "Active request landing page2 no dupes": ". Your agent does not allow the same person to have more than one active process. If you need to modify something of your existing process please contact your agent directly at:",
    "Active request warning": "Some real estate agents may charge extra costs for duplicated requests. We recommend you to consult with your real estate agent before creating a new request.",
    "I understand I may be charged": "I understand my real estate agent may charge me for a duplicate request",
    "CONTINUE MY PROCESS": "CONTINUE MY ALREADY STARTED PROCESS",
    "GO TO REQUEST": "NO, TAKE ME TO MY ACTIVE REQUEST",
    "CREATE ANYWAY": "YES, CREATE A NEW REQUEST ANYWAY",
    "START REQUEST": "START YOUR RENTAL REQUEST",
    "Invalid address": "Invalid address",
    "Invalid address paragraph":
      "does not exist. Please make sure you entered the right URL",
  },
  Nederlands: {
    interested_in_renting_a_property: "Geïnteresseerd in een huurwoning?",
    let_us_know_here: "Laat het ons weten",
    if_you_are_visiting_this_website1:
      "Fijn dat u geïnteresseerd bent in het huren van een woning aangeboden door ",
    if_you_are_visiting_this_website2:
      ". Vul dit formulier in om de woning formeel aan te vragen en het screeningproces te starten",
    first_name: "Voornaam",
    last_name: "Achternaam",
    email: "Email",
    continue: "Verder",
    addressLabel: "Adres huurwoning",
    placeHolderLabel: "Begin met typen...",
    street: "Straatnaam",
    number: "Nummer",
    "Address Line 2": "Toevoeging (optioneel)",
    post_code: "Postcode",
    city: "Plaats",
    Country: "Land",
    rental_price: "Maandelijkse huurprijs (EUR)",
    back: "Terug",
    review_your_application: "Controleer uw huurdersaanvraag",
    your_email_address: "Uw gegevens:",
    your_rental_property: "Adres van huurwoning:",
    monthly_rental_price: "Maandelijkse huurprijs:",
    renting_with_somebody_disclaimer:
      "Als u samen met iemand anders huurt, krijgt u de kans om deze later in dit proces aan uw aanvraag toe te voegen",
    "By ticking, you agree to our":
      "Door aan te vinken gaat u akkoord met onze ",
    "terms and conditions": "algemene voorwaarden",
    and: " en ",
    "privacy policy": "privacy policy",
    tenant_application_sent: "Controleer uw inbox",
    tenant_application_sent_paragraph:
      "Binnen enkele minuten ontvangt u een e-mail (controleer ook uw spamfolder) van onze partner Properize om het screeningproces te voltooien.",
    you_can_click: "U kunt ook op onderstaande knop klikken om verder te gaan:",
    you_can_close_this_window: "U kunt dit venster sluiten.",
    this_tenant_solution_is_powered_by:
      "Dit instapproces wordt mogelijk gemaakt door",
    BACK: "TERUG",
    SUBMIT: "INDIENEN",
    "You need to agree with terms and conditions":
      "U moet akkoord gaan met onze Algemene voorwaarden en Privacybeleid",
    "No valid address":
      "Het adres is ongeldig/onvolledig. Ga terug, voer het adres in en selecteer een van de voorgestelde locaties",
    "Please read": "Let op!",
    "Duplicate popup title": "U heeft een actief verzoek",
    "Active request landing page1": "U (of uw medehuurder) heeft onlangs een verzoek aangemaakt met ",
    "Active request landing page2 dupes": ". Weet u zeker dat u een nieuwe aanvraag wilt maken?",
    "Active request landing page2 no dupes": ". Uw makelaar staat niet toe dat dezelfde persoon meer dan één actief proces heeft. Als u iets in uw bestaande proces moet wijzigen, neem dan rechtstreeks contact op met uw makelaar via:",
    "Active request warning": "Sommige makelaars kunnen extra kosten in rekening brengen voor duplicaat aanvragen. We raden u aan om eerst met uw makelaar te overleggen alvorens een nieuw verzoek in te dienen.",
    "I understand I may be charged": "Ik begrijp dat mijn makelaar kosten in rekening kan brengen voor een duplicaatverzoek.",
    "CONTINUE MY PROCESS": "GA DOOR MET MIJN REEDS GESTARTE PROCES",
    "GO TO REQUEST": "NEE, GA NAAR MIJN ACTIEVE VERZOEK",
    "CREATE ANYWAY": "JA, TOCH EEN NIEUW VERZOEK AANMAKEN",
    "START REQUEST": "START UW HUURAANVRAAG",
    "Invalid address": "Ongeldig URL-adres",
    "Invalid address paragraph":
      "bestaat niet. Controleer of u de juiste URL heeft ingevoerd",
  },
};

function makeid(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

const ExistingRequestPopup = ({
  languageLabel,
  companyName,
  existingRequestOpen,
  flowLink,
  handleSubmit,
  emailAgent,
  duplicatesAllowed,
}) => {
  const [createAnywayCheckbox, setCreateAnywayCheckbox] = useState(false)

  return (
    <Dialog open={existingRequestOpen}>
      <DialogTitle>
        <Grid p={1} align="center">{translationJSON[languageLabel]["Duplicate popup title"]}</Grid>
      </DialogTitle>
      <DialogContent style={{ maxWidth: "450px" }}>
        <DialogContentText>
          {translationJSON[languageLabel]["Active request landing page1"] +
            companyName}{!duplicatesAllowed && translationJSON[languageLabel]["Active request landing page2 no dupes"]}
          {duplicatesAllowed && translationJSON[languageLabel]["Active request landing page2 dupes"]}
          {!duplicatesAllowed && <Grid align="center" my={3}>
            <Link
              href={"mailto:" + emailAgent}
              color="inherit"
              target="_blank"
            >
              {emailAgent}
            </Link>
          </Grid>}

        </DialogContentText>
        {!duplicatesAllowed && <Grid container justifyContent="center" mt={8}>
          <Grid item xs={12}>
            <Button
              variant="contained"
              onClick={() => window.location.assign(flowLink)}
              fullWidth
            >
              {translationJSON[languageLabel]["CONTINUE MY PROCESS"]}
            </Button>
          </Grid>
        </Grid>}
        {duplicatesAllowed && <>
          <Grid my={2} />
          <Alert severity="warning">
            <AlertTitle>{translationJSON[languageLabel]["Please read"]}</AlertTitle>
            {translationJSON[languageLabel]["Active request warning"]}
          </Alert>
          <Grid my={1} />
          <FormControlLabel
            control={
              <Checkbox
                onChange={() =>
                  setCreateAnywayCheckbox(!createAnywayCheckbox)
                }
                value={createAnywayCheckbox}
              />
            }
            label={
              <Typography
                align="left"
                style={{
                  fontSize: "14px",
                }}
              >
                {translationJSON[languageLabel]["I understand I may be charged"]}
              </Typography>
            }
          />

          <Grid container justifyContent="center" mt={3} spacing={1}>
            <Grid item xs={12}>
              <Button
                variant="contained"
                onClick={() => window.location.assign(flowLink)}
                fullWidth
              >
                {translationJSON[languageLabel]["GO TO REQUEST"]}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="outlined"
                color="error"
                onClick={() => handleSubmit(true)}
                fullWidth
                disabled={!createAnywayCheckbox}
              >
                {translationJSON[languageLabel]["CREATE ANYWAY"]}
              </Button>
            </Grid>
          </Grid>
        </>}
      </DialogContent>
    </Dialog>
  );
};

const FirstScreen = ({
  languageLabel,
  getBrightness,
  companyName,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  email,
  setEmail,
  primaryColor,
  secondaryColor,
  goToSecondScreen,
}) => {
  return (
    <Paper sx={paperStyle}>
      <Typography
        style={{
          color: primaryColor,
          fontWeight: "bold",
        }}
      >
        {translationJSON[languageLabel].interested_in_renting_a_property}
      </Typography>
      <Typography
        style={{
          color: primaryColor,
          fontWeight: "bold",
        }}
      >
        {translationJSON[languageLabel].let_us_know_here}
      </Typography>
      <form onSubmit={goToSecondScreen}>
        <Typography style={{ margin: 10 }}>
          {translationJSON[languageLabel].if_you_are_visiting_this_website1}
          {companyName}
          {translationJSON[languageLabel].if_you_are_visiting_this_website2}
        </Typography>
        <CssTextField
          label={translationJSON[languageLabel].first_name}
          variant="outlined"
          value={firstName}
          onChange={(event) => setFirstName(event.target.value)}
          InputLabelProps={{ required: false }}
          fullWidth
          required
        />

        <CssTextField
          label={translationJSON[languageLabel].last_name}
          variant="outlined"
          value={lastName}
          onChange={(event) => setLastName(event.target.value)}
          InputLabelProps={{ required: false }}
          fullWidth
          required
        />
        <CssTextField
          label={translationJSON[languageLabel].email}
          variant="outlined"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          InputLabelProps={{ required: false }}
          type="email"
          fullWidth
          required
        />

        <Button
          type="submit"
          variant="contained"
          fullWidth
          style={{
            borderRadius: 8,
            marginTop: 30,
            fontSize: "18px",
            backgroundColor: secondaryColor,
            color: getBrightness(secondaryColor) > 0.5 ? "black" : "white",
          }}
        >
          {translationJSON[languageLabel].continue}
        </Button>
      </form>
    </Paper>
  );
};

const EnterPropertyLocation = ({
  setStreet,
  setHouseNumber,
  setAddressLine2,
  setPostCode,
  setCity,
  languageLabel,
}) => {

  const navigatorLanguageCode = navigatorLanguage();
  const language = cookies.get("i18next") || navigatorLanguageCode;

  const regionNames = new Intl.DisplayNames([language], {
    type: "region",
  });

  const countries = [
    "NL",
    "BE",
  ].map((code) => (
    <MenuItem key={code} value={code}>
      <Grid
        sx={{ "& > img": { ml: 2, mr: 2, flexShrink: 0 } }}
      >
        <img
          loading="lazy"
          width="20"
          src={`https://flagcdn.com/w20/${code.toLowerCase()}.png`}
          srcSet={`https://flagcdn.com/w40/${code.toLowerCase()}.png 2x`}
          alt=""
        />
        {regionNames.of(code)}
      </Grid>
    </MenuItem>
  ));

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography
          style={{
            fontWeight: "bold",
          }}
        >
          {translationJSON[languageLabel]["addressLabel"]}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={8}>
        <CssTextField
          label={translationJSON[languageLabel]["street"]}
          variant="outlined"
          onChange={(event) => setStreet(event.target.value)}
          fullWidth
          required
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <CssTextField
          label={translationJSON[languageLabel]["number"]}
          variant="outlined"
          onChange={(event) => setHouseNumber(event.target.value)}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={8}>
        <CssTextField
          label={translationJSON[languageLabel]["Address Line 2"]}
          variant="outlined"
          onChange={(event) => setAddressLine2(event.target.value)}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <CssTextField
          label={translationJSON[languageLabel]["post_code"]}
          variant="outlined"
          onChange={(event) => setPostCode(event.target.value)}
          fullWidth
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <CssTextField
          label={translationJSON[languageLabel]["city"]}
          variant="outlined"
          onChange={(event) => setCity(event.target.value)}
          fullWidth
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <CssTextField
          label={translationJSON[languageLabel]["Country"]}
          variant="outlined"
          fullWidth
          select
        >
          {countries}
        </CssTextField>
      </Grid>
    </Grid>
  );
};

const SecondScreen = ({
  languageLabel,
  getBrightness,
  setStreet,
  setHouseNumber,
  setAddressLine2,
  setPostCode,
  setCity,
  rentalPrice,
  setRentalPrice,
  secondaryColor,
  goToFirstScreen,
  goToReviewScreen,
}) => {
  return (
    <Paper sx={paperStyle}>
      <form onSubmit={goToReviewScreen}>

        <Grid item xs={12}>
          <Typography
            style={{
              fontWeight: "bold",
            }}
          >
            {translationJSON[languageLabel]["addressLabel"]}
          </Typography>
        </Grid>

        <Grid item xs={12} mt={2}>

          <AutoCompleteAddress
            setStreet={setStreet}
            setHouseNumber={setHouseNumber}
            setPostCode={setPostCode}
            setCity={setCity}
            addressLabel={translationJSON[languageLabel].addressLabel}
            placeHolderLabel={translationJSON[languageLabel].placeHolderLabel}
          />
        </Grid>

        <div style={{ padding: "20px" }}>
          <CssTextField
            label={translationJSON[languageLabel].rental_price}
            style={{ backgroundColor: "#FFFFFF" }}
            type="number"
            size="small"
            variant="outlined"
            value={rentalPrice}
            onChange={(event) => setRentalPrice(event.target.value)}
            InputProps={{ inputProps: { min: 100 } }}
            fullWidth
            required
          />
        </div>

        <Button
          variant="outlined"
          fullWidth
          onClick={goToFirstScreen}
          style={{
            borderRadius: 8,
            marginTop: 50,
            fontSize: "18px",
            color:
              getBrightness(secondaryColor) > 0.5 ? "black" : secondaryColor,
            borderColor: secondaryColor,
          }}
        >
          {translationJSON[languageLabel].back}
        </Button>

        <Button
          type="submit"
          variant="contained"
          fullWidth
          style={{
            borderRadius: 8,
            marginTop: 10,
            fontSize: "18px",
            backgroundColor: secondaryColor,
            color: getBrightness(secondaryColor) > 0.5 ? "black" : "white",
          }}
        >
          {translationJSON[languageLabel].continue}
        </Button>
      </form>
    </Paper>
  );
};

const InvalidAddressMessage = ({ tenantLink }) => {
  const navigatorLanguageCode = navigatorLanguage();
  const language = cookies.get("i18next") || navigatorLanguageCode;
  const languageLabel = languagesData.find((lan) => lan.code === language).name;

  return (
    <Grid
      container
      spacing={3}
      direction="column"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
      mb={5}
    >
      <Grid item maxWidth="350px" mt={2}>
        <img src={properizeLogo} alt="properizelogo" height="50" />
      </Grid>
      <Grid item align="center" maxWidth="350px">
        <Paper sx={paperStyle}>
          <ErrorIcon color="error" fontSize="large" />

          <Typography sx={{ margin: 2, fontSize: 24, fontWeight: "bold" }}>
            {translationJSON[languageLabel]["Invalid address"]}
          </Typography>
          <Typography sx={{ margin: 2, marginTop: 6, fontSize: 16 }}>
            {"https://app.properize.com/" + tenantLink}
          </Typography>
          <Typography sx={{ margin: 2, fontSize: 16 }}>
            {translationJSON[languageLabel]["Invalid address paragraph"]}
          </Typography>
        </Paper>
      </Grid>
    </Grid>
  );
};

function TenantPage() {
  const id = useParams().id;

  // Company variables
  const [companyName, setCompanyName] = useState("");
  const [logoURL, setLogoURL] = useState("");
  const [primaryColor, setPrimaryColor] = useState("");
  const [secondaryColor, setSecondaryColor] = useState("");
  const [backgroundColor, setBackgroundColor] = useState("");
  const [daysDeadline, setDaysDeadline] = useState("");
  const [costScreening, setCostScreening] = useState("");
  const [accountType, setAccountType] = useState("");
  const [duplicatesAllowed, setDuplicatesAllowed] = useState(false);
  const [suspended, setSuspended] = useState(false);

  // Main agent variables
  const [groupId, setGroupId] = useState("");
  const [emailAgent, setEmailAgent] = useState("");

  // Other variables
  const [error, setError] = useState(false);
  const [renderComponent, setRenderComponent] = useState(false);

  useEffect(() => {
    let payload = {
      key: "tenant_link",
      value: id,
    };

    axios
      .post(configData.backendURL + "/get_properize_groups_data", payload, {
        timeout: 5000,
      })
      .then((response) => {
        setLogoURL(response.data.logo_url);
        setPrimaryColor(response.data.primary_color);
        setSecondaryColor(response.data.secondary_color);
        setBackgroundColor(response.data.background_color);
        setGroupId(response.data.group_id);
        setDaysDeadline(response.data.days_deadline);
        setCompanyName(response.data.company_name);
        setCostScreening(response.data.cost_screen);
        setAccountType(response.data.account_type);
        setEmailAgent(response.data.email_master);
        setSuspended(response.data.suspended);
        setDuplicatesAllowed(response.data.duplicates_allowed);
        setRenderComponent(true);
        setError(response.data.error);
      })
      .catch((err) => {
        setError(true);
        setRenderComponent(true);
      });
  }, [id]);

  const ComponentRender = () => {
    const [showFirstScreen, setShowFirstScreen] = useState(true);
    const [showSecondScreen, setShowSecondScreen] = useState(false);
    const [showReviewScreen, setShowReviewScreen] = useState(false);
    const [tenantAddedSuccesfully, setTenantAddedSuccesfully] = useState(false);
    const [dataSubmitted, setDataSubmitted] = useState(false);
    const [existingRequestOpen, setExistingRequestOpen] = useState(false);
    const [flowLink, setFlowLink] = useState("");

    function goToFirstScreen(e) {
      e.preventDefault();
      setShowFirstScreen(true);
      setShowSecondScreen(false);
      setShowReviewScreen(false);
    }
    function goToSecondScreen(e) {
      setShowFirstScreen(false);
      setShowSecondScreen(true);
      setShowReviewScreen(false);
    }

    function goToReviewScreen(e) {
      setShowFirstScreen(false);
      setShowSecondScreen(false);
      setShowReviewScreen(true);
    }

    function goToAddedSuccesfullyScreen() {
      setShowReviewScreen(false);
      setTenantAddedSuccesfully(true);
      setDataSubmitted(false);
    }

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [street, setStreet] = useState("");
    const [houseNumber, setHouseNumber] = useState("");
    const [addressLine2, setAddressLine2] = useState("");
    const [postCode, setPostCode] = useState("");
    const [city, setCity] = useState("");
    const [rentalPrice, setRentalPrice] = useState("");

    const [languageAnchor, setLanguageAnchor] = useState(null);

    const navigatorLanguageCode = navigatorLanguage();
    const language = cookies.get("i18next") || navigatorLanguageCode;
    const [languageLabel, setLanguageLabel] = useState(
      languagesData.find((lan) => lan.code === language).name
    );

    const handleLanguageClick = (event) => {
      setLanguageAnchor(event.currentTarget);
    };

    function handleSubmit(forceDossierCreation) {
      setExistingRequestOpen(false)
      setDataSubmitted(true);
      let number;
      if (houseNumber) {
        number = houseNumber;
      } else {
        number = "X";
      }
      let cost_of_screening;
      if (accountType === "Trial") {
        cost_of_screening = 0;
      } else if (accountType === "Lite") {
        cost_of_screening = 5;
      } else if (accountType === "Standard") {
        cost_of_screening = 9;
      } else {
        cost_of_screening = Number(costScreening);
      }
      const payload = {
        forceDossierCreation: forceDossierCreation,
        fields: {
          User_id: Number(groupId),
          "Parent request": "PAR" + makeid(16),
          "First name": firstName,
          "Last name": lastName,
          Email: email,
          Language: languagesData.find((lan) => lan.name === languageLabel)
            .code,
          "Property address": street,
          "Property number": number,
          "Property address line 2": addressLine2,
          "Property city": city,
          "Post code": postCode,
          "Notes (private)": "Added from " + companyName + " landing page",
          "Name agent": companyName,
          "Email agent": emailAgent,
          "Account Type": accountType,
          "Cost of the screen (EUR)": cost_of_screening,
          "Property price (EUR)": Number(rentalPrice),
          "Days deadline": Number(daysDeadline),
          ArchiveRemove: 0,
          Status: "In progress",
          CheckA_Status: "In progress",
          CheckB_Status: "In progress",
          CheckC_Status: "In progress",
          "Initiated by": "Tenant via landing page",
          "Flow Completed": "No",
        },
        createdFromLandingPage: true,
      };

      axios
        .post(configData.backendURL + "/create_airtable_record_api", payload)
        .then((response) => {
          setFlowLink(response.data.flow_link);
          if (response.data.existingRequest) {
            setExistingRequestOpen(true);
            setDataSubmitted(false);
          } else {
            goToAddedSuccesfullyScreen();
          }
        });
    }

    const ReviewScreen = () => {
      const [termsAndConditionsCheckbox, setTermsAndConditionsCheckbox] =
        useState(false);
      const [message, setMessage] = useState(false);

      function handleCheckConditionsAccepted(e) {
        e.preventDefault();
        if (!termsAndConditionsCheckbox) {
          setMessage(
            translationJSON[languageLabel][
            "You need to agree with terms and conditions"
            ]
          );
        } else if (!street | !postCode | !city) {
          setMessage(translationJSON[languageLabel]["No valid address"]);
        } else {
          handleSubmit(false);
        }
      }
      return (
        <Paper sx={paperStyle}>
          <Typography
            style={{
              fontSize: "16px",
              fontWeight: "bold",
              color: primaryColor,
            }}
          >
            {translationJSON[languageLabel].review_your_application}
          </Typography>

          <Typography
            style={{
              fontSize: "14px",
              fontWeight: "bold",
              marginTop: 30,
            }}
          >
            {translationJSON[languageLabel].your_email_address}
          </Typography>

          <Typography
            style={{
              fontSize: "14px",
              marginTop: 5,
            }}
          >
            {firstName} {lastName}
          </Typography>
          <Typography
            style={{
              fontSize: "14px",
              marginTop: 5,
            }}
          >
            {email}
          </Typography>

          <Typography
            style={{
              fontSize: "14px",
              fontWeight: "bold",
              marginTop: 30,
            }}
          >
            {translationJSON[languageLabel].your_rental_property}
          </Typography>

          <Typography
            style={{
              fontSize: "14px",
              marginTop: 5,
            }}
          >
            {street} {houseNumber}{addressLine2 && ','} {addressLine2}
          </Typography>

          <Typography
            style={{
              fontSize: "14px",
              marginTop: 5,
            }}
          >
            {postCode} {city}
          </Typography>

          <Typography
            style={{
              fontSize: "14px",
              marginTop: 5,
            }}
          >
            {translationJSON[languageLabel].monthly_rental_price} €{rentalPrice}
          </Typography>

          <Typography
            style={{
              fontSize: "14px",
              fontWeight: "bold",
              marginTop: 30,
            }}
          >
            {translationJSON[languageLabel].renting_with_somebody_disclaimer}
          </Typography>

          <MessagePopup
            state={message}
            setState={setMessage}
            message={message}
          />

          <FormControlLabel
            sx={{
              marginTop: "40px",
              marginBottom: "20px",
              marginLeft: "5px",
              alignItems: "flex-end",
            }}
            control={
              <Checkbox
                onChange={() =>
                  setTermsAndConditionsCheckbox(!termsAndConditionsCheckbox)
                }
                value={termsAndConditionsCheckbox}
              />
            }
            label={
              <Typography
                mt={3}
                align="left"
                style={{
                  fontSize: "12px",
                }}
              >
                {translationJSON[languageLabel]["By ticking, you agree to our"]}
                <Link
                  href="https://properize.com/terms-and-conditions/"
                  color="inherit"
                  target="_blank"
                >
                  {translationJSON[languageLabel]["terms and conditions"]}
                </Link>
                {translationJSON[languageLabel]["and"]}
                <Link
                  href="https://properize.com/privacy-policy/"
                  color="inherit"
                  target="_blank"
                >
                  {translationJSON[languageLabel]["privacy policy"]}
                </Link>
              </Typography>
            }
          />

          <Button
            variant="outlined"
            fullWidth
            onClick={goToSecondScreen}
            style={{
              borderRadius: 8,
              marginTop: 10,
              fontSize: "18px",
              color:
                getBrightness(secondaryColor) > 0.5 ? "black" : secondaryColor,
              borderColor: secondaryColor,
            }}
          >
            {translationJSON[languageLabel]["BACK"]}
          </Button>
          <Button
            type="submit"
            variant="contained"
            fullWidth
            style={{
              borderRadius: 8,
              marginTop: 10,
              fontSize: "18px",
              backgroundColor:
                groupId === "210344709570" ? null : secondaryColor,
              color: getBrightness(secondaryColor) > 0.5 ? "black" : "white",
            }}
            onClick={handleCheckConditionsAccepted}
            disabled={groupId === "210344709570"}
          >
            {translationJSON[languageLabel]["SUBMIT"]}
          </Button>
        </Paper>
      );
    };

    const CompletedScreen = () => {
      return (
        <Paper sx={paperStyle}>
          <Typography
            style={{
              fontSize: "24px",
              fontWeight: "bold",
              color: primaryColor,
            }}
          >
            {translationJSON[languageLabel].tenant_application_sent}
          </Typography>
          <Player
            autoplay
            keepLastFrame
            src="https://assets8.lottiefiles.com/private_files/lf30_nsqfzxxx.json"
          />
          <Typography
            style={{
              fontSize: "16px",
              fontWeight: "bold",
              color: primaryColor,
            }}
          >
            {translationJSON[languageLabel].tenant_application_sent_paragraph}
          </Typography>

          <Typography
            style={{
              marginTop: "20px",
              fontSize: "16px",
              fontWeight: "bold",
              color: primaryColor,
            }}
          >
            {translationJSON[languageLabel].you_can_click}
          </Typography>

          <Button
            variant="contained"
            onClick={() => window.location.assign(flowLink)}
            style={{
              marginTop: 10,
              backgroundColor: primaryColor,
              color: getBrightness(primaryColor) > 0.5 ? "black" : "white",
            }}
          >
            {translationJSON[languageLabel]["START REQUEST"]}
          </Button>
        </Paper>
      );
    };

    return (
      <div>
        <Grid
          container
          wrap="nowrap"
          direction="column"
          alignItems="center"
          justifyContent="space-between"
          minHeight="100vh"
        >
          <Grid
            container
            wrap="nowrap"
            spacing={3}
            direction="column"
            align="center"
            justifyContent="center"
            style={{ minHeight: "85%" }}
            sx={{ marginBottom: 10 }}
          >
            <Grid item>
              {logoURL && (
                <img
                  src={logoURL}
                  alt="logo"
                  height="50"
                  style={{ marginTop: 10, marginBottom: 10 }}
                />
              )}
              {showFirstScreen && (
                <FirstScreen
                  getBrightness={getBrightness}
                  languageLabel={languageLabel}
                  companyName={companyName}
                  firstName={firstName}
                  setFirstName={setFirstName}
                  lastName={lastName}
                  setLastName={setLastName}
                  email={email}
                  setEmail={setEmail}
                  primaryColor={primaryColor}
                  secondaryColor={secondaryColor}
                  goToSecondScreen={goToSecondScreen}
                />
              )}
              {showSecondScreen && (
                <SecondScreen
                  getBrightness={getBrightness}
                  languageLabel={languageLabel}
                  setStreet={setStreet}
                  setHouseNumber={setHouseNumber}
                  setAddressLine2={setAddressLine2}
                  setPostCode={setPostCode}
                  setCity={setCity}
                  rentalPrice={rentalPrice}
                  setRentalPrice={setRentalPrice}
                  secondaryColor={secondaryColor}
                  goToFirstScreen={goToFirstScreen}
                  goToReviewScreen={goToReviewScreen}
                />
              )}
              {showReviewScreen && <ReviewScreen />}
              <LoadingDataComponent alreadyRendered={!dataSubmitted} />
              {tenantAddedSuccesfully && <CompletedScreen />}
            </Grid>
            <Grid item>
              <Typography
                style={{
                  color:
                    getBrightness(backgroundColor) > 0.5 ? "#444444" : "white",
                  fontSize: "12px",
                }}
              >
                {
                  translationJSON[languageLabel]
                    .this_tenant_solution_is_powered_by
                }
              </Typography>
              {getBrightness(backgroundColor) <= 0.5 && (
                <ProperizeWhiteLogo width="90" height="25" />
              )}

              {getBrightness(backgroundColor) > 0.5 && (
                <img
                  src={properizeLogo}
                  alt="properizelogo"
                  width="90"
                  height="25"
                />
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          style={{
            width: "100%",
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
          }}
        >
          <BottomNavigation showLabels sx={{ bgcolor: primaryColor }}>
            <BottomNavigationAction
              sx={{
                color: getBrightness(primaryColor) > 0.5 ? "black" : "white",
              }}
              label="Helpdesk"
              icon={<ContactSupportIcon />}
              onClick={() =>
                window.open("https://properize.com/contact", "_blank")
              }
            />
            <BottomNavigationAction
              label={languageLabel}
              icon={<LanguageIcon />}
              sx={{
                color: getBrightness(primaryColor) > 0.5 ? "black" : "white",
              }}
              onClick={handleLanguageClick}
            ></BottomNavigationAction>

            <LanguageMenuSelection
              anchorEl={languageAnchor}
              setAnchorEl={setLanguageAnchor}
              setLanguageLabel={setLanguageLabel}
            />
          </BottomNavigation>
        </Grid>

        <ExistingRequestPopup
          languageLabel={languageLabel}
          companyName={companyName}
          existingRequestOpen={existingRequestOpen}
          flowLink={flowLink}
          handleSubmit={handleSubmit}
          emailAgent={emailAgent}
          duplicatesAllowed={duplicatesAllowed}
        />
      </div>
    );
  };

  return (
    <div
      style={{
        backgroundColor: backgroundColor,
      }}
    >
      {renderComponent && !error && !suspended && <ComponentRender />}
      <LoadingDataComponent alreadyRendered={renderComponent} />
      {error && <InvalidAddressMessage tenantLink={id} />}
      {suspended && <div>Account suspended. Please contact Properize</div>}
    </div>
  );
}

export default TenantPage;
